import * as React from 'react';

import { Hero, Layout, SEO } from '../components';
import PrivacyPolicy from '../components/privacy-policy';
import CTA from '../components/privacy-policy/privacy-policy-cta';
import HeroImage from '../components/privacy-policy/privacy-policy-hero-image';
import Banner from '../components/why/why-banner';

const PrivacyPolicyPage = () => (
  <Layout
    formName="privacy-policy-bottom-form"
    formTitle="Get in touch with us"
  >
    <SEO title="Privacy Policy | Frontline Removals" />
    <Hero image={<HeroImage />} cta={<CTA />} />
    <PrivacyPolicy />
    <Banner />
  </Layout>
);

export default PrivacyPolicyPage;
